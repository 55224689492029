<template>
  <div class="">
    <div
      class="about-title listItemTitle"
      v-html="$t('about.taxRegister.title')"
    ></div>
    <div class="about-content" v-html="$t('about.taxRegister.content')"></div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped></style>
